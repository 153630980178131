export const FETCH_DEMOGRAPHICS_MAP_DATA = 'Fetch - Demographics Map Data';

export const LOADING_CENSUS_TRACT_DATA = 'Loading - Census Tract Data';
export const LOADING_MSA_DATA = 'Loading - MSA Data';
export const LOADING_NATIONAL_DATA = 'Loading - National Data';

export const RENDER_CENSUS_TRACT_GEOGRAPHY = 'Rendering - Census Tract Geographies';
export const RENDER_MSA_GEOGRAPHY = 'Rendering - MSA Geographies';
export const RENDER_NATIONAL_GEOGRAPHY = 'Rendering - National Geographies';
export const RENDER_COUNTY_GEOGRAPHY = 'Rendering - County Geographies';
export const RENDER_US_GEOGRAPHY = 'Rendering - US Geographies';
export const RENDER_CITY_GEOGRAPHY = 'Rendering - City Geographies';

export const RENDER_GENERIC = 'Rendering - Generic';
