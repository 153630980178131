import React, { memo } from 'react';
import Row from 'antd/lib/grid/row';
import PropTypes from 'prop-types';
import Col from 'antd/lib/grid/col';
import cs from 'classnames';

import './demographicAnalysisLegend.scss';
import { Content } from 'antd/lib/layout/layout';

function DemographicMapLegend(props) {
  const { isSmallerDesign } = props;
  const data = [
    {
      key: 'legend_1',
      className: 'legend success',
      text: 'AIR ≥ 9/10',
    },
    {
      key: 'legend_2',
      className: 'legend warn',
      text: '4/5 < AIR < 9/10',
    },
    {
      key: 'legend_3',
      className: 'legend error',
      text: 'AIR ≤ 4/5',
    },
    {
      key: 'legend_4',
      className: 'legend gray',
      text: 'Insufficient Data For Control Group',
    },
    {
      key: 'legend_5',
      className: 'legend',
      text: 'Insufficient Data For Protected Class',
    },
  ];

  return (
    <Content className={cs('on-demographics-legend', isSmallerDesign ? 'small' : '')}>
      {data.map((d) => (
        <Row className={cs('legend-row', isSmallerDesign ? 'small' : '')} key={d.key}>
          <Col span={24}>
            <div className={cs(d.className, isSmallerDesign ? 'small' : '')} />
            <p className="legend-text-label">{d.text}</p>
          </Col>
        </Row>
      ))}
    </Content>
  );
}

DemographicMapLegend.propTypes = { isSmallerDesign: PropTypes.bool };

DemographicMapLegend.defaultProps = { isSmallerDesign: false };

export default DemographicMapLegend;
