/* eslint-disable react/forbid-prop-types */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/prop-types */
import PropTypes from 'prop-types';
import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import ReactGA from 'react-ga';
import { animated, useSpring } from 'react-spring';

import { text } from 'd3-fetch';
import { easePolyIn } from 'd3-ease';
import ReactTooltip from 'react-tooltip';
import { geoAlbersUsa, geoBounds, geoPath } from 'd3-geo';
import { feature } from 'topojson-client';
import { decompressSync, strFromU8, strToU8 } from 'fflate';

import { moveToTop } from '@utils';
import { ComposableMap, ZoomableGroup } from 'react-simple-maps';
import { Spin } from 'antd';
import {
  DEMOGRAPHICS_MAP_GRANULARITY,
  DEMOGRAPHICS_MAP_US_COUNTIES_URL,
  DEMOGRAPHICS_MAP_US_MSA_URL,
  DEMOGRAPHICS_MAP_US_TRACT_COMPRESSED_URL,
  FETCH_DEMOGRAPHICS_MAP_DATA,
  RENDER_CITY_GEOGRAPHY,
} from '../../constants';

import './demographicsMap.scss';
import { minusIcon, plusIcon, resetIcon } from '../../assets';
import { SimpleLoader } from '../Loader';

import * as mapCountyData from './map-data/us_county_2020_5m.json';
import * as mapMSAData from './map-data/us-cbsa-msa-2020-topo.json';

const promiseCensusTractGeographies = import('./CensusTractGeographies');
const promiseCityGeographies = import('./CityGeographies');
const promiseCountyGeographies = import('./CountyGeographies');
const promiseMSAGeographies = import('./MSAGeographies');
const promiseUSGeographies = import('./USGeographies');
const promiseStateGeographies = import('./StateGeographies');

const MSAGeographies = React.lazy(() => promiseMSAGeographies);
const USGeographies = React.lazy(() => promiseUSGeographies);
const StateGeographies = React.lazy(() => promiseStateGeographies);
const CensusTractGeographies = React.lazy(() => promiseCensusTractGeographies);
const CityGeographies = React.lazy(() => promiseCityGeographies);
const CountyGeographies = React.lazy(() => promiseCountyGeographies);

const DEFAULT_ZOOM = 0.9;
const DEFAULT_CENTER = [-98.5795, 39.8283];
// const DEFAULT_STROKE_WIDTH = [1.1, 1, 0.9, 0.5, 2];

const DEFAULT_STROKE_WIDTH = {
  US: 0.5,
  CountyMSA: 0.45,
  CensusTract: 0.25,
  City: 1.2,
  Selected: 0.25,
};

const getStrokeWidthMultiplier = (factor) => 1 / (1 + Math.log10(factor ** 3));

const getStrokeWidthMultiplierUS = (factor) => 1 / (1 + Math.log10(factor ** 8));

function getMapForView(type) {
  switch (type) {
    case DEMOGRAPHICS_MAP_GRANULARITY.MSA?.VALUE:
      return DEMOGRAPHICS_MAP_US_MSA_URL;
    case DEMOGRAPHICS_MAP_GRANULARITY.COUNTY?.VALUE:
      return DEMOGRAPHICS_MAP_US_COUNTIES_URL;
    default:
      return DEMOGRAPHICS_MAP_US_COUNTIES_URL;
  }
}

function DemographicsMap(props) {
  const { isLoading, addToLoadingQueue, notifyFinishToLoadingQueue } = props;
  const { data, type, censusTractData, mapHeight, calculatedSetOption } = props;
  const { onCensusTractSelect, selectedCensusTract, selected, onAreaSelect, selectedInstitution } =
    props;

  const mapRef = React.createRef();
  const geoAlbersUsaF = geoAlbersUsa();
  const AnimatedZoomableGroup = animated(ZoomableGroup);

  const [toolTipContent, _setToolTipContent] = useState('');

  const setToolTipContent = useCallback((_text) => {
    _setToolTipContent(_text);
  });

  const [mapWidth] = useState(800);

  const [countyAreas, setCountyAreas] = useState({});
  const [msaAreas, setMSAAreas] = useState({});
  const [censusTractArea, setCensusTractArea] = useState({});

  const [strokeWidthSelected, setStrokeWidthSelected] = useState(DEFAULT_STROKE_WIDTH.Selected);
  const [strokeWidthUS, setStrokeWidthUS] = useState(DEFAULT_STROKE_WIDTH.US);
  const [strokeWidthCountyMSA, setstrokeWidthCountyMSA] = useState(DEFAULT_STROKE_WIDTH.Region);
  const [strokeWidthCensusTract, setstrokeWidthCensusTract] = useState(
    DEFAULT_STROKE_WIDTH.District,
  );
  const [strokeWidthCity, setStrokeWidthCity] = useState(DEFAULT_STROKE_WIDTH.City);

  const [mapView, setMapView] = useState(getMapForView(type));
  const [showCities, setShowCities] = useState(false);
  const [showBorders, setShowBorders] = useState(false);

  // ***START SAFARI SPECIFIC ZOOM*** //

  useEffect(() => {
    const isSafari = window.safari !== undefined;
    if (mapRef && isSafari) {
      mapRef.current.addEventListener('wheel', () => {});
    }
  }, []);
  // ***END SAFARI SPECIFIC ZOOM*** //
  const [zoom, _setZoom] = useState(DEFAULT_ZOOM);
  const [center, _setCenter] = useState(DEFAULT_CENTER);
  const [animatedZoom, animatedMapAPI] = useSpring(() => ({
    zoom: 1,
    center: DEFAULT_CENTER,
  }));
  const setZoom = (newZoom, animate) => {
    animatedMapAPI.start({
      config: { mass: 15, tension: 150, friction: 50, clamp: true, restVelocity: 2 },
      from: {
        zoom,
      },
      easing: easePolyIn(1.5),
      immediate: animate === false || isLoading,
      to: {
        zoom: newZoom,
      },
    });
    _setZoom(newZoom);
  };

  const checkIfWithinSameBounds = (startCenter, endCenter) => {
    if (startCenter && startCenter[1] && endCenter[1]) {
      const r1 = geoAlbersUsaF.invert(startCenter);

      const r2 = geoAlbersUsaF.invert(endCenter);

      const maximumDistance = 1.8;
      if (Math.abs(r1[0] - r2[0]) + Math.abs(r1[1] - r2[1]) > maximumDistance) return false;
    }
    return true;
  };

  const setCenter = (newCenter, animate) => {
    const isWithin = checkIfWithinSameBounds(center, newCenter);
    animatedMapAPI.start({
      config: { mass: 10, tension: 120, friction: 40, clamp: true, restVelocity: 2 },
      from: {
        center,
      },
      immediate: animate === false || !isWithin,
      easing: easePolyIn(0.5),
      to: {
        center: newCenter,
      },
    });
    _setCenter(newCenter);
  };
  const onMoveEndHandler = (position) => {
    const r = geoAlbersUsaF(position?.coordinates);

    setZoom(position?.zoom || zoom, false);
    setCenter(r && position?.coordinates ? position?.coordinates : center, false);
  };

  const resetMapZoomCenter = () => {
    animatedMapAPI.start({
      from: {
        center,
        zoom,
      },
      immediate: true,
      to: {
        center: DEFAULT_CENTER,
        zoom: DEFAULT_ZOOM,
      },
    });
    _setCenter(DEFAULT_CENTER);
    _setZoom(DEFAULT_ZOOM);
  };

  const onSelectAreaHandler = useCallback(
    // eslint-disable-next-line no-unused-vars
    (zip, name) => (_geography, _projection, _path) => {
      if (zip === selected.code) {
        onAreaSelect();
      } else {
        onAreaSelect(zip, name);
      }
    },
    [onAreaSelect],
  );

  const onChangeShowCities = useCallback(() => {
    if (!showCities) addToLoadingQueue(RENDER_CITY_GEOGRAPHY);
    setShowCities(!showCities);
    ReactGA.event({
      category: 'ShowCities',
      action: !showCities,
    });
  }, [showCities]);

  const onChangeShowBorders = useCallback(() => {
    setShowBorders(!showBorders);
    ReactGA.event({
      category: 'ShowBorder',
      action: !showBorders,
    });
  }, [showBorders]);

  // eslint-disable-next-line no-unused-vars
  const onCensusTractSelectHandler = useCallback((zip, name) => (geography, projection, path) => {
    if (zip === selectedCensusTract.code) {
      // setCenter(DEFAULT_CENTER);

      onCensusTractSelect();
    } else {
      // const centroid = projection.invert(path.centroid(geography));
      //
      // setCenter(centroid);
      // setZoom(zoom * 1.1); //TODO: this should be relative to the bounding box of the selected census
      onCensusTractSelect(zip, name);
    }
  });

  const onZoomInHandler = useCallback(() => {
    setZoom(zoom * 1.5 < 1000 ? zoom * 1.5 : 1000);
  }, [zoom]);

  const onZoomOutHandler = useCallback(() => {
    setZoom(zoom / 1.5 > 0.5 ? zoom / 1.5 : 0.5);
  }, [zoom]);

  const onRevertChangesHandler = useCallback(() => {
    resetMapZoomCenter();
    onCensusTractSelect();
    onAreaSelect();
    setShowBorders(false);
    setShowCities(false);
  }, []);

  const fetchData = useCallback(async () => {
    addToLoadingQueue(FETCH_DEMOGRAPHICS_MAP_DATA);
    const config = {
      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'private, max-age=3600, stale-while-revalidate=120',
      },
      cache: 'default',
    };
    const fCensusTractArea = async () => {
      const decompressedTract = decompressSync(
        strToU8(await text(DEMOGRAPHICS_MAP_US_TRACT_COMPRESSED_URL, config), true),
      );
      setCensusTractArea(JSON.parse(strFromU8(decompressedTract)));
    };
    await Promise.all([
      fCensusTractArea(),
      setCountyAreas(mapCountyData),
      setMSAAreas(mapMSAData),
    ]).then(() => {
      notifyFinishToLoadingQueue(FETCH_DEMOGRAPHICS_MAP_DATA);
    });
  });

  useMemo(() => {
    const strokeWidthFactor = Math.round(zoom) || 1;
    const newMultiplier = getStrokeWidthMultiplier(strokeWidthFactor);
    const newMultiplierUS = getStrokeWidthMultiplierUS(strokeWidthFactor);
    setStrokeWidthUS(DEFAULT_STROKE_WIDTH.US * newMultiplierUS);
    setstrokeWidthCountyMSA(DEFAULT_STROKE_WIDTH.CountyMSA * newMultiplier);
    setstrokeWidthCensusTract(DEFAULT_STROKE_WIDTH.CensusTract * newMultiplier);
    setStrokeWidthCity(DEFAULT_STROKE_WIDTH.City * newMultiplier);
    setStrokeWidthSelected(DEFAULT_STROKE_WIDTH.Selected * newMultiplier);
  }, [zoom, selected?.code]);

  useMemo(() => {
    if (
      (selectedCensusTract?.code || selected?.code) &&
      ((type === DEMOGRAPHICS_MAP_GRANULARITY.COUNTY?.VALUE &&
        Object.keys(countyAreas).length > 0) ||
        Object.keys(msaAreas).length > 0)
    ) {
      addToLoadingQueue('zoom-center-map');
      let geometries;
      if (type === DEMOGRAPHICS_MAP_GRANULARITY.COUNTY?.VALUE) {
        geometries = feature(countyAreas, countyAreas.objects[Object.keys(countyAreas.objects)[0]]);
      } else {
        geometries = feature(msaAreas, msaAreas.objects[Object.keys(msaAreas.objects)[0]]);
      }
      if (selectedCensusTract?.code) {
        geometries = feature(
          censusTractArea,
          censusTractArea.objects[Object.keys(censusTractArea.objects)[0]],
        );
      }
      const geoHashMap = {};
      geometries?.features.forEach((_feature) => {
        geoHashMap[_feature?.properties?.GEOID] = _feature;
      });
      let geography;
      if (selected?.code) {
        geography = geoHashMap[selected?.code];
      }
      if (selectedCensusTract?.code) {
        geography = geoHashMap[selectedCensusTract?.code];
      }

      const bound = geoBounds(geography);
      const path = geoPath(geoAlbersUsaF);
      const screenBound = [geoAlbersUsaF(bound[0]), geoAlbersUsaF(bound[1])];
      const centroid = geoAlbersUsaF.invert(path.centroid(geography));
      if (screenBound[0] !== null && screenBound[1] !== null) {
        const geoHeight = Math.abs(screenBound[0][1] - screenBound[1][1]);
        const geoWidth = Math.abs(screenBound[0][0] - screenBound[1][0]);

        const PADDING_FACTOR = 0.5;

        let newZoom = Math.min(
          (mapWidth * PADDING_FACTOR) / geoWidth,
          (mapHeight * PADDING_FACTOR) / geoHeight,
        );
        if (newZoom > 150) newZoom = 150;
        setCenter(centroid);
        setZoom(newZoom);
      }
      notifyFinishToLoadingQueue('zoom-center-map');
    }
  }, [countyAreas, msaAreas, type, selected?.code, selectedCensusTract?.code]);

  // move selected County/MSA to last path
  useEffect(() => {
    if (selected?.code) {
      moveToTop('geo-county-msa', 'only-border');
    }
    if (selectedCensusTract?.code) {
      moveToTop('geo-census-tract', 'selected-censustract');
    }
  }, [selected, selectedCensusTract, showBorders, mapHeight, calculatedSetOption]);

  useEffect(() => {
    // setZoom(DEFAULT_ZOOM);
    // setCenter(DEFAULT_CENTER);
  }, [selectedInstitution]);

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    onRevertChangesHandler();

    const newMapView = getMapForView(type);
    if (mapView !== newMapView) {
      setMapView(newMapView);
    }
  }, [type]);

  useEffect(() => {
    let pageUrl = '';
    if (calculatedSetOption) {
      pageUrl = pageUrl.concat(`${calculatedSetOption}/`);
    }
    if (selectedInstitution) {
      pageUrl = pageUrl.concat(`Lender/${selectedInstitution}/`);
    }
    if (selected?.type) {
      pageUrl = pageUrl.concat(`/${selected?.type}/${selected?.code}--${selected?.name}/`);
    }
    if (selectedCensusTract?.code) {
      pageUrl = pageUrl.concat(`/${selectedCensusTract?.code}--${selectedCensusTract?.name}`);
    }

    ReactGA.pageview(pageUrl);
  }, [calculatedSetOption, selectedCensusTract, selected, selectedInstitution]);

  return (
    <div className="nomalized-height-width">
      <div className="nomalized-height-width" style={{ position: 'relative' }} ref={mapRef}>
        <Spin spinning={isLoading} size="large" indicator={<SimpleLoader />}>
          <ComposableMap
            data-tip=""
            className="on-demographic-map"
            projection="geoAlbersUsa"
            height={mapHeight}
          >
            <pattern id="diagonalHatch" patternUnits="userSpaceOnUse" width="4" height="4">
              <path
                d="M-1,1 l2,-2
                    M0,4 l4,-4
                    M3,5 l2,-2"
                style={{
                  stroke: '#e0e0e040',
                  fill: '#e0e0e020',
                  strokeWidth: 0.15,
                  shapeRendering: 'optimizespeed',
                }}
              />
            </pattern>
            <AnimatedZoomableGroup
              center={animatedZoom.center}
              minZoom={0.5}
              maxZoom={10000}
              zoom={animatedZoom.zoom}
              onMoveEnd={onMoveEndHandler}
              translateExtent={[
                [-125, -125],
                [mapWidth + 125, mapHeight + 125],
              ]}
            >
              <React.Suspense fallback={<SimpleLoader />}>
                <StateGeographies strokeWidth={0} />
                <CensusTractGeographies
                  geographyData={censusTractArea}
                  data={censusTractData}
                  selectedArea={selected}
                  selected={selectedCensusTract}
                  strokeWidth={showBorders ? strokeWidthCensusTract : 0}
                  selectedStrokeWidth={strokeWidthSelected}
                  onSelect={onCensusTractSelectHandler}
                  setToolTipContent={setToolTipContent}
                  isLoading={isLoading}
                  addToLoadingQueue={addToLoadingQueue}
                  notifyFinishToLoadingQueue={notifyFinishToLoadingQueue}
                  granularity={type}
                  calculatedSetOption={calculatedSetOption}
                />
                )
                {type === DEMOGRAPHICS_MAP_GRANULARITY.COUNTY?.VALUE ? (
                  <CountyGeographies
                    geographyData={countyAreas}
                    strokeWidth={showBorders ? strokeWidthCountyMSA : 0}
                    selectedStrokeWidth={strokeWidthSelected}
                    data={data}
                    selected={selected}
                    showCities={zoom > 5}
                    onSelect={onSelectAreaHandler}
                    setToolTipContent={setToolTipContent}
                    isLoading={isLoading}
                    addToLoadingQueue={addToLoadingQueue}
                    notifyFinishToLoadingQueue={notifyFinishToLoadingQueue}
                    granularity={type}
                    calculatedSetOption={calculatedSetOption}
                  />
                ) : (
                  <MSAGeographies
                    geographyData={msaAreas}
                    strokeWidth={showBorders ? strokeWidthCountyMSA : 0}
                    selectedStrokeWidth={strokeWidthSelected}
                    data={data}
                    selected={selected}
                    showCities={zoom > 5}
                    onSelect={onSelectAreaHandler}
                    setToolTipContent={setToolTipContent}
                    isLoading={isLoading}
                    addToLoadingQueue={addToLoadingQueue}
                    notifyFinishToLoadingQueue={notifyFinishToLoadingQueue}
                    granularity={type}
                    calculatedSetOption={calculatedSetOption}
                  />
                )}
                <USGeographies strokeWidth={strokeWidthUS} />
                {showCities && (
                  <CityGeographies
                    strokeWidth={strokeWidthCity}
                    setToolTipContent={setToolTipContent}
                    addToLoadingQueue={addToLoadingQueue}
                    notifyFinishToLoadingQueue={notifyFinishToLoadingQueue}
                  />
                )}
              </React.Suspense>
            </AnimatedZoomableGroup>
          </ComposableMap>

          <ReactTooltip className="on-demographic-map__item-tooltip-v2">
            {toolTipContent}
          </ReactTooltip>
        </Spin>

        <div className="on-map-controls">
          <div
            className="single-button"
            onClick={onRevertChangesHandler}
            role="button"
            tabIndex={0}
          >
            <img alt="reset-icon" src={resetIcon} />
          </div>
          <div className="button-groups">
            <div
              className="button-groups__item"
              onClick={onZoomInHandler}
              role="button"
              tabIndex={0}
            >
              <img alt="plus-icon" src={plusIcon} />
            </div>
            <div className="button-groups__divider" />
            <div
              className="button-groups__item"
              onClick={onZoomOutHandler}
              role="button"
              tabIndex={0}
            >
              <img alt="minus-icon" src={minusIcon} />
            </div>
          </div>
        </div>
      </div>
      <div className="on-map-options__under">
        <div className="option" onClick={onChangeShowCities} role="button" tabIndex={-1}>
          {showCities ? 'HIDE CITIES' : 'SHOW CITIES'}
        </div>
        <div className="option" onClick={onChangeShowBorders} role="button" tabIndex={-1}>
          {showBorders ? 'HIDE BORDERS' : 'SHOW BORDERS'}
        </div>
      </div>
    </div>
  );
}

DemographicsMap.propTypes = {
  censusTractData: PropTypes.any,
  data: PropTypes.any,
  mapHeight: PropTypes.number,
  onAreaSelect: PropTypes.func,
  onCensusTractSelect: PropTypes.func,
  selected: PropTypes.shape({
    code: PropTypes.any,
  }),
  selectedCensusTract: PropTypes.shape({
    code: PropTypes.any,
  }),
  type: PropTypes.string,

  isLoading: PropTypes.bool,
  addToLoadingQueue: PropTypes.func,
  notifyFinishToLoadingQueue: PropTypes.func,
  calculatedSetOption: PropTypes.string,
  selectedInstitution: PropTypes.any,
};

DemographicsMap.defaultProps = {
  censusTractData: {},
  data: {},
  mapHeight: undefined,
  onAreaSelect: () => false,
  onCensusTractSelect: () => false,
  selected: {},
  selectedCensusTract: {},
  type: undefined,
  isLoading: true,
  notifyFinishToLoadingQueue: () => false,
  addToLoadingQueue: () => false,
  calculatedSetOption: '',
  selectedInstitution: '',
};

export default memo(DemographicsMap);
