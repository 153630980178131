import countyData from './DEMOGRAPHICS_MAP_COUNTY_DATA.csv';
import countyOptionsData from './DEMOGRAPHICS_MAP_COUNTY_OPTIONS_DATA.csv';

const parsedCountyData = JSON.parse(JSON.stringify(countyData));
const parsedCountyOptionsData = JSON.parse(JSON.stringify(countyOptionsData));

const hashedCountyByCode = {};
parsedCountyData.forEach((_countyData) => {
  hashedCountyByCode[_countyData.code] = _countyData;
});
const parsedFilteredCountyOptionsData = [];

export const DEMOGRAPHICS_MAP_COUNTY_DATA = () => parsedCountyData;
export const DEMOGRAPHICS_MAP_COUNTY_DATA_HASH_CODE = () => hashedCountyByCode;
export const DEMOGRAPHICS_MAP_COUNTY_OPTIONS_DATA = (areaData) =>
  // if (
  //  areaData &&
  //  Object.keys(areaData).length !== 0 &&
  //  parsedFilteredCountyOptionsData.length === 0
  // ) {
  //  parsedFilteredCountyOptionsData = parsedCountyOptionsData.filter((_data) => {
  //    if (areaData[_data.VALUE]?.countyCode === _data.VALUE && areaData[_data.VALUE].air) {
  //      return true;
  //    }
  //    return false;
  //  });
  // }
  // if (parsedFilteredCountyOptionsData.length !== 0) {
  //  return parsedFilteredCountyOptionsData;
  // }
  parsedCountyOptionsData;
