import React, { memo } from 'react';
import { InfoCircleOutlined } from '@ant-design/icons';
import Row from 'antd/lib/grid/row';
import Col from 'antd/lib/grid/col';

import { ContentCard, DemographicsTable } from '@components';

import { useDemographicAnalysisAggregateTable } from './useDemographicAnalysisAggregateTable';

import './demographicAnalysisTable.scss';

function DemographicAnalysisAggregateTable(props) {
  const { data, description, title } = useDemographicAnalysisAggregateTable(props);

  if (!data?.length) {
    return (
      <ContentCard className="on-demographics-analysis__table">
        <div className="no-selected-title">
          <div className="icon">
            <InfoCircleOutlined />
          </div>
          <div className="title">{title}</div>
        </div>
        <div className="no-selected-description">{description}</div>
      </ContentCard>
    );
  }

  return (
    <ContentCard className="on-demographics-analysis__table">
      <Row align="middle" justify="center">
        <Col xs={24} sm={8} md={8}>
          <div className="title has-data hacky-top-padding">{title}</div>
        </Col>
        <Col xs={24} sm={16} md={16}>
          <DemographicsTable data={data} />
        </Col>
      </Row>
    </ContentCard>
  );
}

DemographicAnalysisAggregateTable.propTypes = {};

DemographicAnalysisAggregateTable.defaultProps = {};

export default memo(DemographicAnalysisAggregateTable);
