/* eslint-disable no-bitwise */
import PropTypes from 'prop-types';
import React, { memo, useEffect, useState } from 'react';
import cs from 'classnames';

import { Content } from 'antd/lib/layout/layout';
import Row from 'antd/lib/grid/row';
import Col from 'antd/lib/grid/col';
import Tabs from 'antd/lib/tabs/index';
import { useHotkeys } from 'react-hotkeys-hook';
import { SearchOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { text } from 'd3-fetch';

import { getMapMetaData } from '@utils';
import {
  DemographicAnalysisAggregateTable,
  DemographicAnalysisCensusTractTable,
  DemographicAnalysisTable,
} from '@containers';
import {
  AutoCompleteOptions,
  ContentCard,
  ToggleOptions,
  DemographicMapLegend,
  MainLayout,
} from '@components';
import { Space, Spin } from 'antd';
import { DemographicsMap } from '../DemographicsMap';

import packageJson from '../../../package.json';

import {
  DEMOGRAPHICS_MAP_GRANULARITY,
  DEMOGRAPHICS_MAP_LEI_LIST,
  DEMOGRAPHICS_MAP_CALCULATED_SET_OPTIONS,
  DEMOGRAPHICS_MAP_MSA_OPTIONS_DATA,
  DEMOGRAPHICS_MAP_COUNTY_OPTIONS_DATA,
  DEMOGRAPHICS_MAP_MSA_HASH_CODE,
  DEMOGRAPHICS_MAP_COUNTY_DATA_HASH_CODE,
  isEnabledDebug,
} from '../../constants';

import { useDemographicAnalysis } from './useDemographicAnalysis';
import { useResizeHandler } from './useResizeHandler';

import './demographicAnalysis.scss';

const titleCol = {
  xs: 24,
};

const optsCol = {
  xs: 24,
  md: 5,
};

const mapCol = {
  xs: 24,
  sm: 24,
  md: 24,
  lg: 24,
  xl: 16,
  xxl: 16,
};

const tableCol = {
  xs: 24,
  sm: 24,
  md: 24,
  lg: 24,
  xl: 8,
  xxl: 8,
};

function DemographicAnalysis(props) {
  const {
    match: {
      params: { id: experimentId },
    },
  } = props;
  const {
    mapHeight,

    granularity,
    protectedClassOptions,
    protectedClass,
    calculatedSetOption,
    selectedInstitution,

    areaData,
    censusTractData,

    nationalTableData,
    areaTableData,
    censusTractTableData,

    selectedArea,
    selectedCensusTract,

    setGranularity,
    setSelectedInstitution,
    setProtectedClass,
    setCalculatedSetOption,

    onAreaSelect,
    onCensusTractSelect,

    isLoading,
    addToLoadingQueue,
    notifyFinishToLoadingQueue,
    mapDataVersion,
  } = useDemographicAnalysis(experimentId);

  const { isSmallerDesign } = useResizeHandler();

  const hashCode = (s) =>
    s.split('').reduce((a, b) => {
      // eslint-disable-next-line no-param-reassign
      a = (a << 5) - a + b.charCodeAt(0);
      return a & a;
    }, 0);

  const [mapDataMetadata, setMapDataMetadata] = useState();
  const [showDebug, setShowDebug] = useState(false);
  useEffect(() => {
    if(isEnabledDebug && showDebug) {
      text(getMapMetaData(mapDataVersion)).then((data) => {
        if(data) {
          setMapDataMetadata(JSON.parse(data));
        }
      });
    }
  }, [mapDataVersion, showDebug])

  useHotkeys('shift+d+e+v', async () => {
    if (isEnabledDebug) {
      setShowDebug((_showDebug) => !_showDebug);
    }
  });

  return (
    <MainLayout isSmallerDesign={isSmallerDesign} className={cs('on-demographic-analysis')}>
      {showDebug && (
        <span className="on-dev-notification">
          <span>BUILD | {hashCode(packageJson.version).toString(36)}</span>
          <br />
          <span>MAP DATA : v{mapDataVersion}</span>
          <br />
          <div>{JSON.stringify(mapDataMetadata, null, 2)}</div>
        </span>
      )}
      <Row className="on-demographic-analysis__options" gutter={[10, 10]}>
        <Col {...titleCol}>
          <Row className="on-demographic-analysis__tabs">
            <Col span={24}>
              <div className="title-container">
                <div className="title">MORTGAGE FAIRNESS BY COUNTY (Adverse Impact Ratio)</div>
              </div>
            </Col>
            <Col>
              {false && ( // This is the old menu
                <Tabs animated defaultActiveKey={protectedClass} onChange={setProtectedClass}>
                  {Object.keys(protectedClassOptions).map((key) => (
                    <Tabs.TabPane
                      key={protectedClassOptions[key].VALUE}
                      tab={protectedClassOptions[key].NAME}
                    />
                  ))}
                </Tabs>
              )}
              <Space direction="vertical" className="padding-t-b-10 protected-options">
                <ToggleOptions
                  id="toggle_calculated_set"
                  key={protectedClass}
                  options={protectedClassOptions}
                  selected={protectedClass}
                  disabled={isLoading}
                  onSelect={setProtectedClass}
                  hotkey="alt+y"
                />
              </Space>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className="padding-t-b-10">
        <Col {...optsCol}>
          <AutoCompleteOptions
            id="selectedAreaACO"
            key={`${granularity}_${selectedArea?.code}`}
            options={
              granularity === DEMOGRAPHICS_MAP_GRANULARITY.COUNTY?.VALUE
                ? DEMOGRAPHICS_MAP_COUNTY_OPTIONS_DATA(areaData)
                : DEMOGRAPHICS_MAP_MSA_OPTIONS_DATA(areaData)
            }
            value={selectedArea?.code}
            onChange={(code) => {
              let name;

              if (granularity === DEMOGRAPHICS_MAP_GRANULARITY.COUNTY?.VALUE) {
                name = DEMOGRAPHICS_MAP_COUNTY_DATA_HASH_CODE()[code]?.name;
              } else {
                name = DEMOGRAPHICS_MAP_MSA_HASH_CODE()[code]?.name;
              }

              onAreaSelect(code, name);
            }}
            placeholder={
              granularity === DEMOGRAPHICS_MAP_GRANULARITY.COUNTY?.VALUE
                ? 'Search by County'
                : 'Search by MSA'
            }
            icon={<SearchOutlined />}
            iconSuffix={
              !!selectedArea?.code && (
                <CloseCircleOutlined onClick={() => onAreaSelect(null, null)} />
              )
            }
            hotkey="alt+k"
          />
        </Col>
        {isEnabledDebug() && (
          <Col {...optsCol}>
            <AutoCompleteOptions
              id="selectedInstitutionACO"
              key={selectedInstitution}
              options={DEMOGRAPHICS_MAP_LEI_LIST()}
              value={selectedInstitution}
              onChange={setSelectedInstitution}
              placeholder="Search by Lender"
              uppercase
              icon={<SearchOutlined />}
              iconSuffix={
                !!selectedInstitution && (
                  <CloseCircleOutlined onClick={() => setSelectedInstitution()} />
                )
              }
              hotkey="alt+l"
            />
          </Col>
        )}

        {false && (
          <Col>
            <ToggleOptions
              id="toggle_calculated_set"
              key={calculatedSetOption}
              options={DEMOGRAPHICS_MAP_CALCULATED_SET_OPTIONS}
              selected={calculatedSetOption}
              disabled={isLoading}
              onSelect={setCalculatedSetOption}
              hotkey="alt+i"
            />
          </Col>
        )}
        {isEnabledDebug() && (
          <Col>
            <ToggleOptions
              id="toggle_granularity"
              key={`granularity_${granularity}`}
              options={DEMOGRAPHICS_MAP_GRANULARITY}
              selected={granularity}
              disabled={isLoading}
              onSelect={setGranularity}
              hotkey="alt+o"
            />
          </Col>
        )}
      </Row>

      <ContentCard
        className={cs(
          'on-demographic-analysis-demographic-map-card',
          isSmallerDesign ? 'small' : '',
        )}
      >
        <Row
          className={cs('on-demographic-analysis__map_row', isSmallerDesign ? 'small' : '')}
          align="middle"
          justify="center"
          gutter={[15, 15]}
        >
          <Col
            {...mapCol}
            className={cs('on-map-height-container', isSmallerDesign ? 'small' : '')}
          >
            <Content className="on-demographic-analysis__map">
              <DemographicsMap
                data={areaData}
                censusTractData={censusTractData}
                selected={selectedArea}
                selectedCensusTract={selectedCensusTract}
                onAreaSelect={onAreaSelect}
                onCensusTractSelect={onCensusTractSelect}
                onSelectEmptyArea={() => {}}
                type={granularity}
                selectedInstitution={selectedInstitution}
                isEmptyAreaSelected={false}
                mapHeight={mapHeight}
                isLoading={isLoading}
                addToLoadingQueue={addToLoadingQueue}
                notifyFinishToLoadingQueue={notifyFinishToLoadingQueue}
                calculatedSetOption={calculatedSetOption}
              />
            </Content>
          </Col>
          <Col {...tableCol} className={cs('map-tables', isSmallerDesign ? 'small' : '')}>
            <Row justify="space-between" className="min-height-100">
              <Spin className="small-full-width" spinning={isLoading} size="large">
                <Col
                  className={cs('on-col-tables', isLoading ? 'loading-background' : '')}
                  span={24}
                >
                  <DemographicAnalysisAggregateTable
                    data={
                      nationalTableData && nationalTableData[protectedClass]
                        ? nationalTableData[protectedClass]
                        : []
                    }
                  />
                  <DemographicAnalysisTable
                    airData={areaTableData}
                    granularity={granularity}
                    selectedArea={selectedArea}
                  />
                  <DemographicAnalysisCensusTractTable
                    airData={censusTractTableData}
                    selectedArea={selectedCensusTract}
                  />
                </Col>
              </Spin>
              <Col className={cs('on-dm-legend-table', isSmallerDesign ? 'small' : '')} span={24}>
                <DemographicMapLegend isSmallerDesign={isSmallerDesign} />
              </Col>
            </Row>
          </Col>
        </Row>
      </ContentCard>
    </MainLayout>
  );
}

DemographicAnalysis.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.any,
    }),
  }),
};

DemographicAnalysis.defaultProps = {
  match: {
    params: {
      id: undefined,
    },
  },
};

export default memo(DemographicAnalysis);
