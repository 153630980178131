import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { InfoCircleOutlined } from '@ant-design/icons';

import Row from 'antd/lib/grid/row';
import Col from 'antd/lib/grid/col';

import { ContentCard, DemographicsTable } from '@components';

import './demographicAnalysisTable.scss';

function DemographicAnalysisCensusTractTable(props) {
  const { airData, selectedArea, showHeader } = props;

  if (!selectedArea?.code || selectedArea.code === null) {
    return (
      <ContentCard className="on-demographics-analysis__table">
        <div className="no-selected-title">
          <div className="icon">
            <InfoCircleOutlined />
          </div>
          <div className="title">See Fairness by Census Tract</div>
        </div>
        <div className="no-selected-description">Click on the map to select Census Tract</div>
      </ContentCard>
    );
  }

  return (
    <ContentCard className="on-demographics-analysis__table">
      <Row align="middle" justify="center">
        <Col xs={24} sm={8} md={8}>
          <div className="title has-data">{selectedArea?.name}</div>
        </Col>
        <Col xs={24} sm={16} md={16}>
          <DemographicsTable data={airData?.data} showHeader={showHeader} />
        </Col>
      </Row>
    </ContentCard>
  );
}

DemographicAnalysisCensusTractTable.propTypes = {
  selectedArea: PropTypes.shape({
    code: PropTypes.string,
    name: PropTypes.string,
  }),
  airData: PropTypes.object,
  showHeader: PropTypes.bool,
};

DemographicAnalysisCensusTractTable.defaultProps = {
  selectedArea: {},
  airData: {},
  showHeader: false,
};

export default memo(DemographicAnalysisCensusTractTable);
