/* eslint-disable no-unused-expressions */
/* eslint-disable no-console */
import { useEffect, useState } from 'react';

export const useResizeHandler = () => {
  const [lastKnownScreenWidth, setLastKnownScreenWidth] = useState(window.innerWidth);

  const [isSmallerDesign, setIsSmallerDesign] = useState(false);

  useEffect(() => {
    function handleResize() {
      setLastKnownScreenWidth(window.innerWidth);
    }
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  });

  useEffect(() => {
    const isSmaller =
      ['v=small', 'small=1'].some((flag) => document.location.href.includes(flag)) ||
      lastKnownScreenWidth < 1200;
    setIsSmallerDesign(isSmaller);
  }, [lastKnownScreenWidth]);

  return {
    isSmallerDesign,
  };
};
