import { useEffect, useState } from 'react';

import { getCountyCode } from '../../utils';
import { DEMOGRAPHICS_MAP_GRANULARITY } from '../../constants';

export const useDemographicAnalysisTable = ({
  airData,
  granularity,
  selectedArea,
  selectedDataByGranularity,
  isEmptyAreaSelected,
} = {}) => {
  const [title, setTitle] = useState();
  const [description, setDescription] = useState();
  const [noSelectedDescription, setNoSelectedDescription] = useState();
  const [area, setArea] = useState();

  useEffect(() => {
    let newTitle;
    let newDescription;
    let newNoSelectedDescription;
    const areaName = selectedArea?.name;
    if (granularity === DEMOGRAPHICS_MAP_GRANULARITY.COUNTY?.VALUE) {
      if (selectedArea?.code) {
        newTitle = areaName;
      } else {
        newTitle = 'See Fairness By County';
        newNoSelectedDescription =
          'Click on the map to select a county or search by lender above to see Adverse Impact Ratio over time';
      }
    } else if (isEmptyAreaSelected) {
      newTitle = 'Non-MSA Area';
    } else if (selectedArea?.code) {
      newTitle = areaName;
    } else {
      newTitle = 'See Fairness By MSA';
      newNoSelectedDescription =
        'Click on the map to select a MSA or search by lender above to see Adverse Impact Ratio over time';
    }

    setTitle(newTitle);
    setDescription(newDescription);
    setNoSelectedDescription(newNoSelectedDescription);
  }, [granularity, selectedArea, area, isEmptyAreaSelected]);

  useEffect(() => {
    let result;
    if (granularity && selectedArea?.code) {
      const countyCode = getCountyCode(granularity, selectedArea);
      result = selectedDataByGranularity?.find((c) => c?.countyCode === countyCode);
    }
    setArea(result);
  }, [granularity, selectedArea, selectedDataByGranularity]);

  return {
    isEmptyAreaSelected,
    airData: airData?.data || [],
    granularity,
    selectedArea,
    title,
    description,
    noSelectedDescription,
  };
};
