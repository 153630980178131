/* eslint-disable no-param-reassign */
/* eslint-disable no-bitwise */
import React from 'react';
import ReactGA from 'react-ga';
import { DemographicAnalysis } from './components';

import './App.scss';
import { APP_CONSTANTS } from './constants';

ReactGA.initialize(APP_CONSTANTS.GA_TRACKING_ID, {
  titleCase: false,
});
ReactGA.pageview(window.location.pathname + window.location.search);

function App() {
  return (
    <div className="App">
      <DemographicAnalysis />
    </div>
  );
}

export default App;
