import React, { memo } from 'react';
import { fairplayLogo } from '../../assets';
import './simpleLoader.scss';

function SimpleLoader() {
  return (
    <div className="on-loading-screen">
      <div className="on-loading-logo">
        <div className="on-fair-play-logo">
          <img alt="logo" src={fairplayLogo} />
        </div>
      </div>
    </div>
  );
}

SimpleLoader.propTypes = {};

SimpleLoader.defaultProps = {};

export default memo(SimpleLoader);
