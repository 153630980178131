import leiData from './DEMOGRAPHICS_MAP_LEI_LIST.csv';

const parsedLeiData = JSON.parse(JSON.stringify(leiData));
const filteredLeiData = parsedLeiData.filter(
  (ele, ind) => ind === parsedLeiData.findIndex((elem) => elem.VALUE === ele.VALUE),
);
filteredLeiData.forEach((item) => {
  // eslint-disable-next-line no-param-reassign
  item.NAME = item.NAME.toUpperCase();
});

export const DEMOGRAPHICS_MAP_LEI_LIST = () => filteredLeiData;
