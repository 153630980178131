import ReactGA from 'react-ga';
import {
  APP_CONSTANTS,
  DEMOGRAPHICS_MAP_APPROVED_LOANS_OPTIONS,
  DEMOGRAPHICS_MAP_CALCULATED_SET_OPTIONS,
  DEMOGRAPHICS_MAP_GRANULARITY,
  DEMOGRAPHICS_MAP_MSA_HASH_COUNTY,
  DEMOGRAPHICS_MAP_MSA_HASH_MSA,
} from '../constants';

const demographicAnalysisMapSuccessPrimaryColor = '#7dcd69';
const demographicAnalysisMapSuccessSecondaryColor = '#7dcd69B3';
const demographicAnalysisMapWarnPrimaryColor = '#f1d12e';
const demographicAnalysisMapWarnSecondaryColor = '#f1d12eB3';
const demographicAnalysisMapErrorPrimaryColor = '#ff5b35';
const demographicAnalysisMapErrorSecondaryColor = '#ff5b35b3';

export function getAIRColors(rule, { air: el }) {
  let fill;
  let selected;
  let border;

  if (rule === DEMOGRAPHICS_MAP_APPROVED_LOANS_OPTIONS.RULE_9_10.VALUE) {
    if (el >= 0.9) {
      fill = demographicAnalysisMapSuccessSecondaryColor;
      selected = demographicAnalysisMapSuccessPrimaryColor;
      border = demographicAnalysisMapSuccessPrimaryColor;
    } else if (el > 0.8) {
      fill = demographicAnalysisMapWarnSecondaryColor;
      selected = demographicAnalysisMapWarnPrimaryColor;
      border = demographicAnalysisMapWarnPrimaryColor;
    } else {
      fill = demographicAnalysisMapErrorSecondaryColor;
      selected = demographicAnalysisMapErrorPrimaryColor;
      border = demographicAnalysisMapErrorPrimaryColor;
    }
  } else if (el >= 0.8) {
    fill = demographicAnalysisMapSuccessSecondaryColor;
    selected = demographicAnalysisMapSuccessPrimaryColor;
    border = demographicAnalysisMapSuccessPrimaryColor;
  } else {
    fill = demographicAnalysisMapErrorSecondaryColor;
    selected = demographicAnalysisMapErrorPrimaryColor;
    border = demographicAnalysisMapErrorPrimaryColor;
  }

  return { fill, selected, border };
}

export function getAIRColorsV2(rule, { air: el }) {
  let color;

  if (rule === DEMOGRAPHICS_MAP_APPROVED_LOANS_OPTIONS.RULE_9_10.VALUE) {
    if (el === 'gray') {
      color = 'gray';
    } else if (el >= 0.9) {
      color = 'success';
    } else if (el > 0.8) {
      color = 'warn';
    } else {
      color = 'error';
    }
  } else if (el === 'gray') {
    color = 'gray';
  } else if (el >= 0.8) {
    color = 'success';
  } else {
    color = 'error';
  }

  return { color };
}

export const getAreaCode = (mapType, countyCode) => {
  if (mapType === DEMOGRAPHICS_MAP_GRANULARITY.MSA?.VALUE) {
    return DEMOGRAPHICS_MAP_MSA_HASH_COUNTY()[countyCode]?.msaCode;
  }
  return countyCode;
};

export const getCountyCode = (mapType, code) => {
  if (mapType === DEMOGRAPHICS_MAP_GRANULARITY.MSA?.VALUE) {
    return DEMOGRAPHICS_MAP_MSA_HASH_MSA()[code]?.countyCode;
  }
  return code;
};

// eslint-disable-next-line no-underscore-dangle
const _getDemographicDataUrl = (
  type = 'single',
  granularity,
  institution,
  year,
  calculatedSet,
  isCensusTract = false,
  mapDataVersion,
) => {
  const dataBaseUrl = `${APP_CONSTANTS.MAP_DATA_BASE_URL}/v${mapDataVersion}`;

  if (type === 'metadata') {
    return `${dataBaseUrl}/metadata.json`
  }
  if (type === 'single') {
    if (isCensusTract && institution) {
      return `${dataBaseUrl}/census-tract/lei/${calculatedSet}/${year}/${institution}_census_tract_air_${year}.m3dfc`;
    }
    if (
      isCensusTract &&
      calculatedSet === DEMOGRAPHICS_MAP_CALCULATED_SET_OPTIONS.APPLICANTS.VALUE
    ) {
      return `${dataBaseUrl}/census-tract/all/${calculatedSet}/census_tract_air_${year}.m3dfc`;
    }
    if (isCensusTract) {
      return `${dataBaseUrl}/census-tract/all/${calculatedSet}/census_tract_demographic_air_${year}.m3dfc`;
    }

    if (granularity === DEMOGRAPHICS_MAP_GRANULARITY.MSA?.VALUE && institution) {
      return `${dataBaseUrl}/msa/lei/${calculatedSet}/${year}/${institution}_derived_msa-md_air_${year}.m3dfc`;
    }

    if (
      granularity === DEMOGRAPHICS_MAP_GRANULARITY.MSA?.VALUE &&
      calculatedSet === DEMOGRAPHICS_MAP_CALCULATED_SET_OPTIONS.APPLICANTS.VALUE
    ) {
      return `${dataBaseUrl}/msa/all/${calculatedSet}/derived_msa-md_air_${year}.m3dfc`;
    }
    if (granularity === DEMOGRAPHICS_MAP_GRANULARITY.MSA?.VALUE) {
      return `${dataBaseUrl}/msa/all/${calculatedSet}/derived_msa-md_demographic_air_${year}.m3dfc`;
    }

    if (institution) {
      return `${dataBaseUrl}/county/lei/${calculatedSet}/${year}/${institution}_county_code_air_${year}.m3dfc`;
    }
    if (calculatedSet === DEMOGRAPHICS_MAP_CALCULATED_SET_OPTIONS.APPLICANTS.VALUE) {
      return `${dataBaseUrl}/county/all/${calculatedSet}/county_code_air_${year}.m3dfc`;
    }

    return `${dataBaseUrl}/county/all/${calculatedSet}/county_code_demographic_air_${year}.m3dfc`;
  }

  if (institution) {
    return `${dataBaseUrl}/national/lei/${calculatedSet}/${year}/${institution}_usa_air.m3dfc`;
  }
  if (calculatedSet === DEMOGRAPHICS_MAP_CALCULATED_SET_OPTIONS.APPLICANTS.VALUE) {
    return `${dataBaseUrl}/national/all/${calculatedSet}/usa_${year}_air.m3dfc`;
  }
  return `${dataBaseUrl}/national/all/${calculatedSet}/usa_${year}_demographic_air.m3dfc`;
};

export const getDemographicDataUrl = (
  type = 'single',
  granularity,
  institution,
  year,
  calculatedSet,
  isCensusTract = false,
  mapDataVersion,
  preload = false,
) => {
  const dataUrl = _getDemographicDataUrl(
    type,
    granularity,
    institution,
    year,
    calculatedSet,
    isCensusTract,
    mapDataVersion,
  );
  if (!preload) {
    ReactGA.event({
      category: 'Data Download',
      action: `Map Data Queued for Download - ${dataUrl.replace(
        APP_CONSTANTS.MAP_DATA_BASE_URL,
        '',
      )}`,
      nonInteraction: true,
    });
  }
  return dataUrl;
};

export const getMapMetaData = (mapDataVersion) => _getDemographicDataUrl(
  'metadata',
  undefined,
  undefined,
  undefined,
  undefined,
  undefined,
  mapDataVersion,
)

export const moveToTop = (geoId, elementClassName) => {
  const geography = document.getElementById(geoId);
  if (geography) {
    const selectedAreaElement = geography.getElementsByClassName(elementClassName);
    const selectedArea = selectedAreaElement?.item(0);

    if (selectedArea) {
      geography.removeChild(selectedArea);
      geography.appendChild(selectedArea);
    }
  }
};
