import React, { memo, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useHotkeys } from 'react-hotkeys-hook';
import AutoComplete from 'antd/lib/auto-complete';
import Input from 'antd/lib/input/Input';

import './autoCompleteOptions.scss';

function AutoCompleteOptions(props) {
  const { options, placeholder, icon, iconSuffix, value, id, uppercase } = props;
  const { onChange, hotkey } = props;

  const inputEl = useRef(null);
  useHotkeys(hotkey, () => inputEl.current.focus());

  const [processedOptions, setProcessedOptions] = useState([]);
  const [query, setQuery] = useState('');
  const [lastQuery, setLastQuery] = useState('');

  const onSearchHandler = (text) => {
    setQuery(text);
  };

  const onSelectHandler = (data) => {
    onChange(data);
  };

  const onFocusHandler = () => {
    setLastQuery(query);
    setQuery('');
  };

  const onBlurHandler = () => {
    if (query === '' && lastQuery !== '') {
      setQuery(lastQuery);
    }
  };

  const onKeyDown = (event) => {
    if (event?.key === 'Enter' && processedOptions?.length) {
      onSelectHandler(processedOptions[0]?.value || undefined);
    }
  };

  const transformInputOption = (option) => ({
    label: uppercase ? option?.NAME?.toUpperCase() : option?.NAME,
    value: option?.VALUE,
  });

  useEffect(() => {
    if (value) {
      const selectedOption = options?.find((option) => option.VALUE === value);
      setQuery(selectedOption?.NAME);
    }
  }, [value]);

  useEffect(() => {
    if (options?.length) {
      const result = options.map((item) => transformInputOption(item));
      setProcessedOptions(result);
    }
  }, [options]);

  useEffect(() => {
    if (options?.length) {
      const result = options
        .filter((option) => {
          if (option?.NAME?.toLowerCase()?.includes(query?.toLowerCase())) {
            return true;
          }
          return false;
        })
        .map((option) => transformInputOption(option));
      setProcessedOptions(result);
    }
  }, [query]);

  return (
    <AutoComplete
      className="on-auto-complete-options"
      value={query}
      options={processedOptions}
      onSearch={onSearchHandler}
      onFocus={onFocusHandler}
      onBlur={onBlurHandler}
      onSelect={onSelectHandler}
      onKeyDown={onKeyDown}
      ref={inputEl}
    >
      <Input id={id} placeholder={placeholder} prefix={icon} suffix={iconSuffix} />
    </AutoComplete>
  );
}

AutoCompleteOptions.propTypes = {
  options: PropTypes.array,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  icon: PropTypes.node,
  iconSuffix: PropTypes.node,
  onChange: PropTypes.func,
  id: PropTypes.string,
  uppercase: PropTypes.bool,
  hotkey: PropTypes.string,
};

AutoCompleteOptions.defaultProps = {
  options: [],
  value: undefined,
  onChange: () => {},
  placeholder: '',
  icon: null,
  iconSuffix: null,
  id: '',
  uppercase: false,
  hotkey: '',
};

export default memo(AutoCompleteOptions);
