import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { InfoCircleOutlined } from '@ant-design/icons';
import Row from 'antd/lib/grid/row';
import Col from 'antd/lib/grid/col';
import { ContentCard, DemographicsTable } from '@components';
import { useDemographicAnalysisTable } from './useDemographicAnalysisTable';
import './demographicAnalysisTable.scss';

function DemographicAnalysisTable(props) {
  const { airData, selectedArea, title, description, noSelectedDescription } =
    useDemographicAnalysisTable(props);
  const { showHeader } = props;
  if (!selectedArea?.code || selectedArea.code === null) {
    return (
      <ContentCard className="on-demographics-analysis__table">
        <div className="no-selected-title">
          <div className="icon">
            <InfoCircleOutlined />
          </div>
          <div className="title">{title}</div>
        </div>
        <div className="no-selected-description">{noSelectedDescription}</div>
      </ContentCard>
    );
  }

  return (
    <ContentCard className="on-demographics-analysis__table">
      <Row align="middle" justify="center">
        <Col xs={24} sm={8} md={8}>
          <div className="title has-data">{title}</div>
          <div className="description">{description}</div>
        </Col>
        <Col xs={24} sm={16} md={16}>
          <DemographicsTable data={airData} showHeader={showHeader} />
        </Col>
      </Row>
    </ContentCard>
  );
}

DemographicAnalysisTable.propTypes = {
  selectedArea: PropTypes.shape({
    code: PropTypes.string,
    name: PropTypes.string,
    type: PropTypes.string,
  }),
  showHeader: PropTypes.bool,
};

DemographicAnalysisTable.defaultProps = {
  selectedArea: {},
  showHeader: false,
};

export default memo(DemographicAnalysisTable);
