import msaData from './DEMOGRAPHICS_MAP_MSA_DATA.csv';
import msaOptionsData from './DEMOGRAPHICS_MAP_MSA_OPTIONS_DATA.csv';

const parsedMSAData = JSON.parse(JSON.stringify(msaData));
const parsedMSAOptionsData = JSON.parse(JSON.stringify(msaOptionsData));

const hashedMSAByCounty = {};
const hashedMSAByMsa = {};
const hashedMSAByCode = {};
parsedMSAData.forEach((_msaData) => {
  hashedMSAByCounty[_msaData.countyCode] = _msaData;
  hashedMSAByMsa[_msaData.msaCode] = _msaData;
  hashedMSAByCode[_msaData.code] = _msaData;
});
const parsedFilteredMSAOptionsData = [];

export const DEMOGRAPHICS_MAP_MSA_DATA = () => parsedMSAData;
export const DEMOGRAPHICS_MAP_MSA_HASH_COUNTY = () => hashedMSAByCounty;
export const DEMOGRAPHICS_MAP_MSA_HASH_MSA = () => hashedMSAByMsa;
export const DEMOGRAPHICS_MAP_MSA_HASH_CODE = () => hashedMSAByCode;
export const DEMOGRAPHICS_MAP_MSA_OPTIONS_DATA = (areaData) =>
  // if (areaData && Object.keys(areaData).length !== 0 && parsedFilteredMSAOptionsData.length === 0) {
  //  parsedFilteredMSAOptionsData = parsedMSAOptionsData.filter((_data) => {
  //    if (areaData[_data.VALUE]?.msaCode === _data.VALUE && areaData[_data.VALUE].air) {
  //      return true;
  //    }
  //    return false;
  //  });
  // }
  // if (parsedFilteredMSAOptionsData.length !== 0) {
  //  return parsedFilteredMSAOptionsData;
  // }
  parsedMSAOptionsData;
