import React, { memo } from 'react';
import cs from 'classnames';
import PropTypes from 'prop-types';

import './cards.scss';
import Card from 'antd/lib/card';

function ContentCard(props) {
  const { className } = props;
  const { children } = props;
  return <Card className={cs('on-content-card', className)}>{children}</Card>;
}

ContentCard.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

ContentCard.defaultProps = {
  children: undefined,
  className: '',
};

export default memo(ContentCard);
