/* eslint-disable no-undef */
export const APP_CONSTANTS = {
  MAP_TOPOLOGY_BASE_URL: process.env.MAP_TOPOLOGY_BASE_URL,
  MAP_DATA_BASE_URL: process.env.MAP_DATA_BASE_URL,
  GA_TRACKING_ID: process.env.GA_TRACKING_ID,
  MAP_DATA_VERSION: process.env.MAP_DATA_VERSION,
};

const DEBUG_ENABLED_DOMAINS = ['localhost', 'fairplay-staging.tech'];
const ALLOWED_IFRAME_DOMAINS = ['fairplay-staging.tech', 'fair-play.ai'];
export const isEnabledDebug = () => DEBUG_ENABLED_DOMAINS.includes(window.location.hostname);

// flag for smaller design
export const isInIFrame = () => window !== window.parent;

// this is a reduntand check (cloudfront is blocking iframe integrations)
export const checkIFrame = () => {
  if (isInIFrame()) {
    if (ALLOWED_IFRAME_DOMAINS.includes(window.location.hostname)) {
      return true;
    }
    return false;
  }
  return true;
};
