import { APP_CONSTANTS } from './appConstants';

export const DEMOGRAPHICS_MAP_US_COUNTIES_URL = '/map-data/us_county_2020_5m.compressed';
export const DEMOGRAPHICS_MAP_US_MSA_URL = '/map-data/us-cbsa-msa-2020-topo.compressed';

export const DEMOGRAPHICS_MAP_US_TRACT_COMPRESSED_URL = `${APP_CONSTANTS.MAP_TOPOLOGY_BASE_URL}/tract-topo-v10.compressed`; // cant be loaded locally, too big for node/react

export const DEMOGRAPHICS_MAP_GRANULARITY = {
  COUNTY: {
    NAME: 'Counties',
    VALUE: 'county',
  },
  MSA: {
    NAME: 'MSA',
    VALUE: 'msa',
  },
  /* CENSUS_TRACT: {
    NAME: 'Census Tract',
    VALUE: 'tract',
  }, */
};

export const DEMOGRAPHICS_MAP_PROTECTED_CLASS = {
  FEMALE: {
    NAME: 'Female',
    VALUE: 'female',
  },
  BLACK: {
    NAME: 'Black',
    VALUE: 'black',
  },
  Hispanic: {
    NAME: 'Hispanic',
    VALUE: 'hispanic',
  },
  API: {
    NAME: 'API',
    VALUE: 'api',
  },
  AIAN: {
    NAME: 'AIAN',
    VALUE: 'aian',
  },
  NHNP: {
    NAME: 'NHNP',
    VALUE: 'nhnp',
  },
};

export const DEMOGRAPHICS_MSA_TABLE_DATA = {
  AGE: 'msa_ages.csv',
  GENDER: 'msa_genders.csv',
  RACE: 'msa_races.csv',
};

export const DEMOGRAPHICS_MAP_APPROVED_LOANS_OPTIONS = {
  RULE_9_10: {
    NAME: '9/10 Rule',
    VALUE: 90,
  },
  RULE_4_5: {
    NAME: '4/5 Rule',
    VALUE: 80,
  },
};

export const DEMOGRAPHIC_DATA_YEARS = ['2020', '2019', '2018'];
export const DEMOGRAPHIC_DATA_SET = ['county', 'msa'];
export const DEMOGRAPHIC_DATA_TYPE = [
  'applicants',
  // 'population'
];

export const DEMOGRAPHICS_MAP_CALCULATED_SET_OPTIONS = {
  APPLICANTS: {
    NAME: 'Applicants',
    VALUE: 'applicants',
  },
  // POPULATION: {
  //  NAME: 'Population',
  //  VALUE: 'population',
  // },
};
