import React, { memo } from 'react';
import cs from 'classnames';
import { useHotkeys } from 'react-hotkeys-hook';
import PropTypes from 'prop-types';

import './toggleOptions.scss';

function ToggleOptions(props) {
  const { options, selected, onSelect, disabled, hotkey } = props;

  useHotkeys(hotkey, () => {
    Object.keys(options)?.forEach((key) => {
      const item = options[key];
      if (selected !== item.VALUE) {
        onSelect(item.VALUE);
      }
    });
  });

  return (
    <div className="on-toggle-options">
      {Object.keys(options)?.map((key) => {
        const item = options[key];
        return (
          <div
            key={item.VALUE}
            id={`btnID_${item.VALUE}`}
            className={cs('on-toggle-option', selected === item.VALUE && 'selected')}
            onClick={() => {
              if (!disabled) onSelect(item.VALUE);
            }}
            disabled={disabled}
          >
            {item.NAME}
          </div>
        );
      })}
    </div>
  );
}

ToggleOptions.propTypes = {
  options: PropTypes.object,
  selected: PropTypes.string,
  onSelect: PropTypes.func,
  disabled: PropTypes.bool,
  hotkey: PropTypes.string,
};

ToggleOptions.defaultProps = {
  options: {},
  selected: undefined,
  onSelect: () => false,
  disabled: false,
  hotkey: '',
};

export default memo(ToggleOptions);
