import React, { memo } from 'react';
import cs from 'classnames';
import PropTypes from 'prop-types';

import { Content } from 'antd/lib/layout/layout';
import Col from 'antd/lib/grid/col';
import Row from 'antd/lib/grid/row';

import ContentCard from './ContentCard';

import './generalContentCard.scss';

function GeneralContentCard(props) {
  const {
    title,
    children,
    className,
    headerRowJustify,
    headerRowAlign,
    headerComponents,
    headerRowGutter,
    colProps,
  } = props;

  return (
    <ContentCard className={cs(className, 'on-mdd-content-card')}>
      <Content className="on-general-content-card__header">
        <Row gutter={headerRowGutter} justify={headerRowJustify} align={headerRowAlign}>
          {!!title && (
            <Col {...colProps}>
              <div className="title">{title}</div>
            </Col>
          )}
          {headerComponents?.map((component) => component)}
        </Row>
      </Content>
      <Content className="on-general-content-card__content">{children}</Content>
    </ContentCard>
  );
}

GeneralContentCard.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  colProps: PropTypes.object,
  headerComponents: PropTypes.array,
  title: PropTypes.string,
  headerRowJustify: PropTypes.string,
  headerRowAlign: PropTypes.string,
  headerRowGutter: PropTypes.array,
};

GeneralContentCard.defaultProps = {
  children: null,
  className: undefined,
  colProps: {},
  headerComponents: [],
  title: undefined,
  headerRowJustify: undefined,
  headerRowAlign: undefined,
  headerRowGutter: [20, 0],
};

export default memo(GeneralContentCard);
