import { useEffect, useState } from 'react';

import { DEMOGRAPHICS_MAP_GRANULARITY } from '../../constants';

export const useDemographicAnalysisAggregateTable = ({
  data,
  granularity,
  isEmptyAreaSelected,
} = {}) => {
  const [title, setTitle] = useState();
  const [description, setDescription] = useState();

  useEffect(() => {
    setTitle('US Aggregate');
    setDescription('Loading Data...');
  }, [granularity, isEmptyAreaSelected]);

  return {
    isEmptyAreaSelected,
    data,
    granularity,
    title,
    description,
  };
};
