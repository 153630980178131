/* eslint-disable no-unused-expressions */
/* eslint-disable no-console */
import { useCallback, useEffect, useMemo, useState } from 'react';
import ReactGA from 'react-ga';
import { useHotkeys } from 'react-hotkeys-hook';
import { text } from 'd3-fetch';
import { csvParse } from 'd3-dsv';
import { decompressSync, strFromU8, strToU8 } from 'fflate';
import { formatPercentage, getAIRColorsV2, getDemographicDataUrl } from '../../utils';

import {
  DEMOGRAPHICS_MAP_APPROVED_LOANS_OPTIONS,
  DEMOGRAPHICS_MAP_PROTECTED_CLASS,
  DEMOGRAPHICS_MAP_GRANULARITY,
  DEMOGRAPHIC_DATA_YEARS,
  DEMOGRAPHIC_DATA_SET,
  DEMOGRAPHIC_DATA_TYPE,
  DEMOGRAPHICS_MAP_CALCULATED_SET_OPTIONS,
  LOADING_CENSUS_TRACT_DATA,
  LOADING_MSA_DATA,
  LOADING_NATIONAL_DATA,
  RENDER_COUNTY_GEOGRAPHY,
  RENDER_MSA_GEOGRAPHY,
  APP_CONSTANTS,
  isEnabledDebug,
} from '../../constants';

const createSelectedArea = (name, code, type) => ({
  name,
  code,
  type,
});

const createSelectedCensusTract = (name, code) => ({
  name,
  code,
});

const createSelectedAreaTableData = (name, code, type, data) => ({
  name,
  code,
  type,
  data,
});

const createSelectedCensusTractTableData = (name, code, data) => ({
  name,
  code,
  data,
});

export const useDemographicAnalysis = () => {
  /**
   * HELPERS
   */
  const [isLoading, setisLoading] = useState(true);
  const [loadingQueue, setLoadingQueue] = useState([]);

  const addToLoadingQueue = (loadEvent) => {
    console.log('[Loading Queue] - ADDED - ', loadEvent);
    setLoadingQueue((_loadingQueue) => [
      ..._loadingQueue,
      { event: loadEvent, startTime: new Date() },
    ]);
    setisLoading(true);
  };

  const notifyFinishToLoadingQueue = (finishedEvent) => {
    setLoadingQueue((_loadingQueue) => {
      let foundEvent = false;
      if (_loadingQueue.length === 0 || !finishedEvent) return _loadingQueue;
      console.table('[Loading Queue] - INFO', _loadingQueue, finishedEvent);
      return _loadingQueue.filter((_event) => {
        if (_event.event === finishedEvent && !foundEvent) {
          console.log(
            `[Loading Queue] - REMOVED - ${_event.event} (took : ${Math.round(
              new Date() - _event.startTime,
            )}ms)`,
          );
          foundEvent = true;
          return false;
        }
        return true;
      });
    });
  };

  useEffect(() => {
    console.log('[Loading Queue] - Starting loadingQueueChecker');

    const loadingQueueChecker = setInterval(() => {
      setLoadingQueue((_loadingQueue) => {
        const loadingIndexes = [];
        const isLoadingQueueStuck = () => {
          console.log('[Loading Queue] - Running loadingQueueChecker');
          if (_loadingQueue.length > 0) {
            // eslint-disable-next-line consistent-return
            _loadingQueue.forEach((_event, _index) => {
              const timeDiff = new Date() - _event.startTime;
              console.log(`${_index} : ${timeDiff}`);
              if (timeDiff > 60000) {
                console.warn(
                  `[Loading Queue] - STUCK - ${_event.event} (took : ${Math.round(timeDiff)}ms)`,
                );
              }
              if (timeDiff > 90000) {
                console.warn(
                  `[Loading Queue] - STUCK - ${_event.event} (took : ${Math.round(timeDiff)}ms)`,
                );
                loadingIndexes.push(_index);
                return loadingIndexes;
              }
            });

            return loadingIndexes;
          }
          return loadingIndexes;
        };
        if (isLoadingQueueStuck().length >= 1) {
          return [];
        }
        return _loadingQueue;
      });
    }, 2500);
  }, []);

  useEffect(() => {
    if (loadingQueue.length === 1 && loadingQueue[0].event === 'Saving Census Tract') {
      notifyFinishToLoadingQueue('Saving Census Tract');
    }
    if (loadingQueue.length >= 1) {
      if (!isLoading) setisLoading(true);
    } else {
      console.log('[Loading Queue] - Finished Loading');
      setisLoading(false);
    }
  }, [loadingQueue]);

  /**
   * Map Debug Toys
   */
  // TODO: Remove
  const [mapDataVersion, setMapDataVersion] = useState(Number(APP_CONSTANTS.MAP_DATA_VERSION));
  useHotkeys('shift+d+1', () => {
    if (isEnabledDebug) setMapDataVersion((_mapDataVersion) => _mapDataVersion - 1);
  });
  useHotkeys('shift+d+2', () => {
    if (isEnabledDebug) setMapDataVersion((_mapDataVersion) => _mapDataVersion + 1);
  });

  const mapHeight = 600;
  /**
   * Selected entities p1
   */
  const [selectedArea, setSelectedArea] = useState(createSelectedArea());
  const [selectedCensusTract, setSelectedCensusTract] = useState(createSelectedCensusTract());

  /**
   * Options
   */
  const [granularity, _setGranularity] = useState(DEMOGRAPHICS_MAP_GRANULARITY.COUNTY?.VALUE);
  const [selectedCensusTractHistory, setSelectedCensusTractHistory] = useState(undefined);

  const setGranularity = useCallback((newGranularity) => {
    _setGranularity(newGranularity);
    setSelectedArea(createSelectedArea());
    setSelectedCensusTract(createSelectedCensusTract());
    if (newGranularity === DEMOGRAPHICS_MAP_GRANULARITY.COUNTY?.VALUE) {
      addToLoadingQueue(RENDER_COUNTY_GEOGRAPHY);
    } else if (newGranularity === DEMOGRAPHICS_MAP_GRANULARITY.MSA?.VALUE) {
      addToLoadingQueue(RENDER_MSA_GEOGRAPHY);
    }
    ReactGA.event({
      category: 'Changed Granularity',
      action: newGranularity,
    });
  });

  const [calculatedSetOption, _setCalculatedSetOption] = useState(
    DEMOGRAPHICS_MAP_CALCULATED_SET_OPTIONS.APPLICANTS.VALUE,
  );

  const setCalculatedSetOption = useCallback((newSetOption) => {
    if (!selectedCensusTractHistory) {
      addToLoadingQueue('Saving Census Tract');
      setSelectedCensusTractHistory(selectedCensusTract);
    }
    _setCalculatedSetOption(newSetOption);
    // setSelectedArea(createSelectedArea());
    // setSelectedCensusTract(createSelectedCensusTract());
    ReactGA.event({
      category: 'Changed Set Option',
      action: newSetOption,
    });
  });

  useEffect(() => {
    if (selectedCensusTractHistory && loadingQueue.length === 0) {
      setSelectedCensusTract(
        createSelectedCensusTract(selectedCensusTractHistory.name, selectedCensusTractHistory.code),
      );
      setSelectedCensusTractHistory(undefined);
      notifyFinishToLoadingQueue('Saving Census Tract');
    }
  }, [selectedCensusTractHistory, loadingQueue]);

  const [protectedClassOptions, _setProtectedClassOptions] = useState(
    DEMOGRAPHICS_MAP_PROTECTED_CLASS,
  );
  const [protectedClass, _setProtectedClass] = useState(
    DEMOGRAPHICS_MAP_PROTECTED_CLASS.FEMALE.VALUE,
  );

  const setProtectedClass = useCallback((newProtectedClass) => {
    if (!selectedCensusTractHistory) {
      addToLoadingQueue('Saving Census Tract');
      setSelectedCensusTractHistory(selectedCensusTract);
    }
    _setProtectedClass(newProtectedClass);
    ReactGA.event({
      category: 'Changed Protected Class',
      action: newProtectedClass,
    });
  });

  const [ruleOption, setRuleOption] = useState(
    DEMOGRAPHICS_MAP_APPROVED_LOANS_OPTIONS.RULE_9_10.VALUE,
  );
  const [selectedInstitution, setSelectedInstitution] = useState();

  /**
   * Map data
   */
  const [rawAreaData, setRawAreaData] = useState({});
  const [rawCensusTractData, setRawCensusTractData] = useState({});
  const [areaData, setAreaData] = useState({});

  /**
   * Table data
   */
  const [nationalTableData, setNationalTableData] = useState([]);
  const [areaTableData, setAreaTableData] = useState(createSelectedAreaTableData());
  const [censusTractTableData, setCensusTractTableData] = useState(
    createSelectedCensusTractTableData(),
  );

  /**
   * Selected entities p2
   */

  const fetchCensusTract = useCallback(async () => {
    addToLoadingQueue(LOADING_CENSUS_TRACT_DATA);

    setRawCensusTractData({});
    // setCensusTractData([]);
    setCensusTractTableData(createSelectedCensusTractTableData());

    const promises = [];

    DEMOGRAPHIC_DATA_YEARS.forEach((year) => {
      promises.push(
        text(
          getDemographicDataUrl(
            'single',
            granularity,
            selectedInstitution,
            year,
            calculatedSetOption,
            true,
            mapDataVersion,
          ),
          {
            headers: {
              'Content-Type': 'text/csv',
              'Cache-Control': 'private, max-age=3600, stale-while-revalidate=120',
            },
            cache: 'default',
          },
          true,
        ).catch(() => false),
      );
    });

    await Promise.all(promises)
      .then((results) => {
        const newRawData = {};

        results
          ?.filter((n) => n)
          ?.forEach((_result, index) => {
            const result = csvParse(strFromU8(decompressSync(strToU8(_result, true))));
            const year = DEMOGRAPHIC_DATA_YEARS[index];

            if (!newRawData[year]) {
              newRawData[year] = {};
            }

            (result || []).forEach((item) => {
              Object.keys(DEMOGRAPHICS_MAP_PROTECTED_CLASS).forEach((key) => {
                const protectedClassTmp = DEMOGRAPHICS_MAP_PROTECTED_CLASS[key].VALUE;

                newRawData[year][protectedClassTmp] = newRawData[year][protectedClassTmp] || [];

                newRawData[year][protectedClassTmp].push({
                  air: item[protectedClassTmp],
                  censusTract: item.census_tract,
                });
              });
            });
          });

        notifyFinishToLoadingQueue(LOADING_CENSUS_TRACT_DATA);
        setRawCensusTractData(newRawData);
      })
      .catch((error) => {
        console.log('error fetching');
        console.error(error);
        notifyFinishToLoadingQueue(LOADING_CENSUS_TRACT_DATA);
      });
  }, [calculatedSetOption, selectedInstitution, mapDataVersion]);

  const fetchCountyMSA = useCallback(async () => {
    addToLoadingQueue(LOADING_MSA_DATA);

    // setSelectedArea(createSelectedArea());
    // setSelectedCensusTract(createSelectedCensusTract());
    console.log(selectedArea, selectedCensusTract);

    setRawAreaData({});
    setAreaData([]);
    setAreaTableData(createSelectedAreaTableData());

    const promises = [];

    DEMOGRAPHIC_DATA_YEARS.forEach((year) => {
      promises.push(
        text(
          getDemographicDataUrl(
            'single',
            granularity,
            selectedInstitution,
            year,
            calculatedSetOption,
            false,
            mapDataVersion,
          ),
          {
            headers: {
              'Content-Type': 'text/csv',
              'Cache-Control': 'private, max-age=3600, stale-while-revalidate=120',
            },
            cache: 'default',
          },
        ).catch(() => false),
      );
    });

    await Promise.all(promises)
      .then((results) => {
        const newRawData = {};

        results
          ?.filter((n) => n)
          ?.forEach((_result, index) => {
            const result = csvParse(strFromU8(decompressSync(strToU8(_result, true))));
            const year = DEMOGRAPHIC_DATA_YEARS[index];

            if (!newRawData[year]) {
              newRawData[year] = {};
            }

            result.forEach((item) => {
              Object.keys(DEMOGRAPHICS_MAP_PROTECTED_CLASS).forEach((key) => {
                const protectedClassTmp = DEMOGRAPHICS_MAP_PROTECTED_CLASS[key].VALUE;

                newRawData[year][protectedClassTmp] = newRawData[year][protectedClassTmp] || [];

                newRawData[year][protectedClassTmp].push({
                  air: item[protectedClassTmp],
                  countyCode: item.county_code,
                  msaCode: item['derived_msa-md'],
                });
              });
            });
          });

        notifyFinishToLoadingQueue(LOADING_MSA_DATA);
        setRawAreaData(newRawData);
      })
      .catch((error) => {
        console.log('error fetching');
        console.error(error);
        notifyFinishToLoadingQueue(LOADING_MSA_DATA);
      });
  }, [granularity, calculatedSetOption, selectedInstitution, mapDataVersion]);

  const fetchNational = useCallback(async () => {
    const result = {};
    const promises = [];

    addToLoadingQueue(LOADING_NATIONAL_DATA);

    DEMOGRAPHIC_DATA_YEARS.forEach((year) => {
      promises.push(
        text(
          getDemographicDataUrl(
            'national',
            granularity,
            selectedInstitution,
            year,
            calculatedSetOption,
            false,
            mapDataVersion,
          ),
          {
            headers: {
              'Content-Type': 'text/csv',
              'Cache-Control': 'private, max-age=3600, stale-while-revalidate=120',
            },
            cache: 'default',
          },
        ).catch(() => false),
      );
    });

    await Promise.all(promises)
      .then((_results) => {
        const results = [];
        _results.forEach((_result) => {
          results.push(csvParse(strFromU8(decompressSync(strToU8(_result, true)))));
        });
        Object.keys(DEMOGRAPHICS_MAP_PROTECTED_CLASS).forEach((key) => {
          const dataItem = {};
          const protectedClassValue = DEMOGRAPHICS_MAP_PROTECTED_CLASS[key].VALUE;
          DEMOGRAPHIC_DATA_YEARS.forEach((year, index) => {
            const resultPerYear = results[index] || [];

            if (!result[protectedClassValue]) {
              result[protectedClassValue] = [];
            }
            const item = resultPerYear?.find((r) => r.class === protectedClassValue);

            dataItem[`air${year}`] = item?.air
              ? `${formatPercentage(item?.air * 100) || 'N/A'}&&${
                  getAIRColorsV2(ruleOption, item)?.color
                }`
              : 'N/A';
          });
          result[protectedClassValue].push(dataItem);
        });

        notifyFinishToLoadingQueue(LOADING_NATIONAL_DATA);
        setNationalTableData(result);
      })
      .catch((error) => {
        console.error('error fetching national data');
        console.error(error);
        notifyFinishToLoadingQueue(LOADING_NATIONAL_DATA);
      });
  }, [selectedInstitution, calculatedSetOption, mapDataVersion]);

  /**
   * Fetch CSV data for Census Tract
   */
  useEffect(() => {
    fetchCensusTract();
  }, [fetchCensusTract]);

  /**
   * Fetch CSV data for County/MSA
   */
  useEffect(() => {
    fetchCountyMSA();
  }, [fetchCountyMSA]);

  /**
   * Fetch CSV data for National level
   */
  useEffect(() => {
    fetchNational();
  }, [fetchNational]);

  const preFetchData = useCallback(async () => {
    const promises = [];
    DEMOGRAPHIC_DATA_TYPE.forEach((_calculatedSetOption) => {
      DEMOGRAPHIC_DATA_SET.forEach((_granularity) => {
        DEMOGRAPHIC_DATA_YEARS.forEach((year) => {
          promises.push(
            text(
              getDemographicDataUrl(
                'single',
                _granularity,
                selectedInstitution,
                year,
                _calculatedSetOption,
                false,
                mapDataVersion,
                true,
              ),
              {
                headers: {
                  'Content-Type': 'text/csv',
                  'Cache-Control': 'private, max-age=3600, stale-while-revalidate=120',
                },
                cache: 'default',
              },
            ).catch(() => false),
          );
        });
      });
    });
  }, [selectedInstitution, mapDataVersion]);


  // TODO : Check if this should happen or not
  // useEffect(() => {
  //   setTimeout(() => {
  //     preFetchData();
  //   }, 10000);
  // }, [preFetchData]);

  const onAreaSelect = useCallback(
    (code, name) => {
      if (code === null) {
        setSelectedArea(createSelectedArea());
        setSelectedCensusTract(createSelectedCensusTract());
      }
      if (code === selectedArea.code) {
        setSelectedArea(createSelectedArea());
        setSelectedCensusTract(createSelectedCensusTract());
      } else {
        setSelectedCensusTract(createSelectedCensusTract());
        setSelectedArea(createSelectedArea(name, code, granularity));
      }
    },
    [granularity],
  );

  /**
   * Process data for current Map view on County/MSA (granularity/protected class, rule)
   */
  useEffect(() => {
    if (rawAreaData) {
      const year = DEMOGRAPHIC_DATA_YEARS[0];
      if (rawAreaData && rawAreaData[year] && Object.keys(rawAreaData[year]).length) {
        const selectedRawData = rawAreaData[year][protectedClass];

        const result = selectedRawData
          ?.filter((item) => item.air !== 'no data')

          ?.map((item) => ({
            ...item,
            colors: getAIRColorsV2(ruleOption, item)?.color,
            code: item.countyCode || item.msaCode,
          }));

        const dictResult = {};
        result.forEach((item) => {
          dictResult[item.code] = item;
        });

        setAreaData(dictResult);

        if (selectedArea?.code) {
          if (dictResult[selectedArea?.code]?.code) {
            onAreaSelect(dictResult[selectedArea?.code]?.code, selectedArea?.name);
          } else {
            // setSelectedArea(createSelectedArea());
            // setSelectedCensusTract(createSelectedCensusTract());
          }
        }
      }
    }
  }, [rawAreaData, protectedClass, ruleOption]);

  /**
   * Process data for current Map view on Census Tract (granularity/protected class, rule)
   */
  const censusTractData = useMemo(() => {
    const year = DEMOGRAPHIC_DATA_YEARS[0];

    if (
      rawCensusTractData &&
      rawCensusTractData[year] &&
      Object.keys(rawCensusTractData[year]).length
    ) {
      const selectedRawData = rawCensusTractData[year][protectedClass];

      const dictResult = {};
      let index = 0;
      while (index < selectedRawData.length) {
        const item = selectedRawData[index];
        if (item.air !== 'no data') {
          dictResult[item.censusTract] = {
            ...item,
            colors: getAIRColorsV2(ruleOption, item)?.color,
            code: item.censusTract,
          };
        }
        index += 1;
      }

      return dictResult;
    }

    return undefined;
  }, [rawCensusTractData, protectedClass, ruleOption]);

  /**
   * Calculate available protected classes for selected County/MSA or census tract
   */
  // useEffect(() => {
  //  if (!selectedArea?.code && !selectedCensusTract?.code) {
  //    setProtectedClassOptions(DEMOGRAPHICS_MAP_PROTECTED_CLASS);
  //  }
  // }, [selectedArea, selectedCensusTract?.code]);

  // useEffect(() => {
  //  if (!selectedCensusTract?.code && selectedArea?.code) {
  //    const result = {};
  //    Object.keys(DEMOGRAPHICS_MAP_PROTECTED_CLASS).forEach((key) => {
  //      const protectedClassValue = DEMOGRAPHICS_MAP_PROTECTED_CLASS[key].VALUE;
  //      const found = rawAreaData[DEMOGRAPHIC_DATA_YEARS[0]][protectedClassValue].find((item) =>
  //        granularity === DEMOGRAPHICS_MAP_GRANULARITY.COUNTY?.VALUE
  //          ? item?.countyCode === selectedArea?.code
  //          : item?.msaCode === selectedArea?.code,
  //      );
  //      // osobno, volim stvari tipa 'no data' -> staviti u konstantu
  //      if (found && found.air !== 'no data') {
  //        result[key] = DEMOGRAPHICS_MAP_PROTECTED_CLASS[key];
  //      }
  //    });
  //
  //    // setProtectedClassOptions(result);
  //  }
  // }, [selectedArea, selectedCensusTract?.code]);

  // useEffect(() => {
  //  if (selectedCensusTract?.code) {
  //    const result = {};
  //    Object.keys(DEMOGRAPHICS_MAP_PROTECTED_CLASS).forEach((key) => {
  //      const protectedClassValue = DEMOGRAPHICS_MAP_PROTECTED_CLASS[key].VALUE;
  //      const found = rawCensusTractData[DEMOGRAPHIC_DATA_YEARS[0]][protectedClassValue].find(
  //        (item) => item?.censusTract === selectedCensusTract?.code,
  //      );
  //
  //      if (found && found.air !== 'no data') {
  //        result[key] = DEMOGRAPHICS_MAP_PROTECTED_CLASS[key];
  //      }
  //    });
  //
  //    // setProtectedClassOptions(result);
  //  }
  // }, [selectedCensusTract?.code]);

  useEffect(() => {
    if (selectedArea?.code) {
      const result = {
        ...selectedArea,
      };
      const airData = {};
      DEMOGRAPHIC_DATA_YEARS.forEach((year) => {
        let dataset = {};
        if (rawAreaData[year] && rawAreaData[year][protectedClass]) {
          dataset = rawAreaData[year][protectedClass].find((item) =>
            granularity === DEMOGRAPHICS_MAP_GRANULARITY.COUNTY?.VALUE
              ? item?.countyCode === selectedArea?.code
              : item?.msaCode === selectedArea?.code,
          );
        }
        // Ovdje isto. ovaj air -> lak
        airData[`air${year}`] =
          dataset?.air && dataset?.air !== 'no data'
            ? `${formatPercentage(dataset?.air * 100) || 'N/A'}&&${
                getAIRColorsV2(ruleOption, dataset)?.color
              }`
            : 'N/A';

        // if (airData[`air${year}`] === 'undefined%%gray') airData[`air${year}`] = 'N/A';
      });

      const protectedClassKey = Object.keys(DEMOGRAPHICS_MAP_PROTECTED_CLASS).find(
        (key) => DEMOGRAPHICS_MAP_PROTECTED_CLASS[key].VALUE === protectedClass,
      );

      result.data = [
        {
          ...airData,
          groupName: DEMOGRAPHICS_MAP_PROTECTED_CLASS[protectedClassKey]?.NAME,
        },
      ];

      setAreaTableData(result);
    }
  }, [selectedArea, protectedClass]);

  useEffect(() => {
    if (selectedCensusTract?.code) {
      const result = {
        ...selectedCensusTract,
      };
      const airData = {};
      DEMOGRAPHIC_DATA_YEARS.forEach((year) => {
        let dataset = {};
        if (rawCensusTractData[year] && rawCensusTractData[year][protectedClass]) {
          dataset = rawCensusTractData[year][protectedClass].find(
            (item) => item?.censusTract === selectedCensusTract?.code,
          );
        }
        airData[`air${year}`] =
          dataset?.air && dataset?.air !== 'no data'
            ? `${formatPercentage(dataset?.air * 100) || 'N/A'}&&${
                getAIRColorsV2(ruleOption, dataset)?.color
              }`
            : 'N/A';

        // if (airData[`air${year}`] === 'undefined%%gray') airData[`air${year}`] = 'N/A';
      });

      const protectedClassKey = Object.keys(DEMOGRAPHICS_MAP_PROTECTED_CLASS).find(
        (key) => DEMOGRAPHICS_MAP_PROTECTED_CLASS[key].VALUE === protectedClass,
      );

      result.data = [
        {
          ...airData,
          groupName: DEMOGRAPHICS_MAP_PROTECTED_CLASS[protectedClassKey]?.NAME,
        },
      ];

      setCensusTractTableData(result);
    }
  }, [selectedCensusTract, protectedClass]);

  const onCensusTractSelect = useCallback((code, name) => {
    if (code === selectedArea.code) {
      setSelectedCensusTract(createSelectedArea());
    } else {
      setSelectedCensusTract(createSelectedCensusTract(name, code));
    }
  });

  return {
    mapHeight,

    granularity,
    protectedClassOptions,
    protectedClass,
    ruleOption,
    calculatedSetOption,
    selectedInstitution,

    areaData,
    censusTractData,

    nationalTableData,
    areaTableData,
    censusTractTableData,

    selectedArea,
    selectedCensusTract,

    setGranularity,
    setSelectedInstitution,
    setRuleOption,
    setProtectedClass,
    setCalculatedSetOption,

    onAreaSelect,
    onCensusTractSelect,

    // newLoadingSystem
    isLoading,
    addToLoadingQueue,
    notifyFinishToLoadingQueue,

    mapDataVersion,
  };
};
