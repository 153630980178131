import React, { memo } from 'react';
import cs from 'classnames';
import PropTypes from 'prop-types';
import Select from 'antd/lib/select';

import './options.scss';

function Options(props) {
  const { options, value, size, onChange, className } = props;

  if (!options) {
    return null;
  }

  const selectedOptionKey = Object.keys(options).find((key) => options[key].VALUE === value);

  return (
    <Select
      className={cs(className, 'on-select-options')}
      defaultValue={selectedOptionKey ? options[selectedOptionKey].NAME : 'Select Value'}
      onChange={onChange}
      size={size}
    >
      {Object.keys(options)
        .filter((key) => options[key].VALUE !== value)
        .map((key) => (
          <Select.Option key={key} value={options[key].VALUE}>
            {options[key].NAME}
          </Select.Option>
        ))}
    </Select>
  );
}

Options.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  options: PropTypes.object,
  onChange: PropTypes.func,
  className: PropTypes.string,
  size: PropTypes.oneOf(['default', 'small', 'large']),
};

Options.defaultProps = {
  value: undefined,
  options: {},
  onChange: () => false,
  className: undefined,
  size: 'default',
};

export default memo(Options);
