/* eslint-disable no-param-reassign */
import React, { memo } from 'react';
import PropTypes from 'prop-types';
import Table from 'antd/lib/table';

import './demographicsTable.scss';

const defaultColumns = [
  {
    title: '2020',
    dataIndex: 'air2020',
    key: 'air2020',
    align: 'center',
    render(text) {
      if (text.includes('undefined')) text = 'N/A';
      return {
        props: {
          className: text.split('&&')[1],
        },
        children: <div id="DCT_col_1">{text ? text.split('&&')[0] : 'N/A'}</div>,
      };
    },
  },
  {
    title: '2019',
    dataIndex: 'air2019',
    key: 'air2019',
    align: 'center',
    render(text) {
      if (text.includes('undefined')) text = 'N/A';
      return {
        props: {
          className: text.split('&&')[1],
        },
        children: <div id="DCT_col_2">{text ? text.split('&&')[0] : 'N/A'}</div>,
      };
    },
  },
  {
    title: '2018',
    dataIndex: 'air2018',
    key: 'air2018',
    align: 'center',
    render(text) {
      if (text.includes('undefined')) text = 'N/A';
      return {
        props: {
          className: text.split('&&')[1],
        },
        children: <div id="DCT_col_3">{text ? text.split('&&')[0] : 'N/A'}</div>,
      };
    },
  },
];

function DemographicsTable(props) {
  const { data, columns, showHeader } = props;
  return (
    <Table
      className="on-demographics-table"
      columns={columns}
      dataSource={data}
      pagination={false}
      showHeader={showHeader}
      rowKey="air2020"
    />
  );
}

DemographicsTable.propTypes = {
  data: PropTypes.array,
  columns: PropTypes.array,
  showHeader: PropTypes.bool,
};

DemographicsTable.defaultProps = {
  data: [],
  columns: defaultColumns,
  showHeader: true,
};

export default memo(DemographicsTable);
