import { MONTH_NAME } from '../constants';

export function abbreviateNumber(value, precision = 3) {
  let newValue = value;
  const suffixes = ['', 'K', 'M', 'B', 'T'];
  let suffixNum = 0;
  while (newValue >= 1000) {
    newValue /= 1000;
    suffixNum++;
  }

  newValue = newValue.toPrecision(precision);

  newValue += suffixes[suffixNum];
  return newValue;
}

export function formatNumber(value) {
  return new Intl.NumberFormat('en-US').format(value);
}

export function formatCurrency(value, shouldAbbreviate = true, precision = 2) {
  let result = value;

  if (precision && !shouldAbbreviate) {
    result = formatNumber(result?.toFixed(precision));
  }

  if (shouldAbbreviate) {
    result = abbreviateNumber(value);
  }

  return `$${result}`;
}

export function isNumeric(str) {
  let result = str;

  if (typeof str === 'number') {
    result = str.toString();
  }

  if (typeof result !== 'string') {
    return false;
  }
  return !Number.isNaN(result) && !Number.isNaN(parseFloat(result));
}

export function formatPercentage(value, numberOfDecimals = 2) {
  let preparedValue = value;
  if (typeof value !== 'number') {
    preparedValue = +value;
  }

  if (!isNumeric(preparedValue)) {
    return undefined;
  }

  return `${preparedValue.toFixed(numberOfDecimals)}%`;
}

export function formatNonLeadingZeroNumber(value) {
  if (!isNumeric(value)) {
    return undefined;
  }

  let result = value;

  if (typeof value === 'string') {
    result = +value;
  }

  result = result.toFixed(2);

  result = result.substring(result.indexOf('.'));

  return result;
}

export const getDateString = (date) => {
  const result = `${MONTH_NAME[date.getMonth()]} ${date.getDate()}, ${date.getFullYear()}`;
  return result;
};
