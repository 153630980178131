import React, { memo } from 'react';
import cs from 'classnames';
import PropTypes from 'prop-types';

import Layout from 'antd/lib/layout';

import './mainLayout.scss';

const { Content } = Layout;

function MainLayout(props) {
  const { className, backgroundColor, children, isSmallerDesign } = props;

  return (
    <Layout className={cs(className, 'on-layout', backgroundColor, isSmallerDesign ? 'small' : '')}>
      <Layout className={cs('on-main-layout', backgroundColor)}>
        <Content className={cs('on-main-content', backgroundColor)}>{children}</Content>
      </Layout>
    </Layout>
  );
}

MainLayout.propTypes = {
  className: PropTypes.string,
  backgroundColor: PropTypes.string,
  children: PropTypes.node,
  isSmallerDesign: PropTypes.bool,
};

MainLayout.defaultProps = {
  className: undefined,
  backgroundColor: undefined,
  children: null,
  isSmallerDesign: false,
};

export default memo(MainLayout);
